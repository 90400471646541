/**
 * MenuIcon.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const MenuIcon = ({ onPress, icon }) => {
  return (
    <TouchableOpacity style={styles.iconContainer} onPress={onPress}>
      <Ionicons name={icon} size={28} color="black" />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  iconContainer: {
    marginLeft: 10
  }
});

export default React.memo(MenuIcon);
