// services/api.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { EXPO_PUBLIC_API_ENDPOINT, EXPO_PUBLIC_APP_MODE } from '../config/app.conf';

export const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: EXPO_PUBLIC_API_ENDPOINT,
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json');
            headers.set('Accept', 'application/json');
            headers.set('Access-Control-Allow-Origin', '*');
            headers.set('Token', `Bearer` || null);
            headers.set('app-mode', EXPO_PUBLIC_APP_MODE)
            return headers;
        },
    }),
    endpoints: (builder) => ({
        //auth endpoints
        login: builder.mutation({
            query: (body) => ({
                url: '/login',
                method: 'POST',
                body,
            }),
        }),
        register: builder.mutation({
            query: (body) => ({
                url: '/register',
                method: 'POST',
                body,
            }),
        }),
        //forgot password endpoints
        forgotPassword: builder.mutation({
            query: (body) => ({
                url: '/forgetpassword',
                method: 'POST',
                body,
            }),
        }),

        //changePassword

        changePassword: builder.mutation({
            query: (body) => ({
                url: '/changePassword',
                method: 'POST',
                body,
            }),
        }),

        //phone-search

        phoneSearch: builder.mutation({
            query: (body) => ({
                url: '/phone-search',
                method: 'POST',
                body,
            }),
        }),

        //complete-profile

        completeProfile: builder.mutation({
            query: (body) => ({
                url: '/complete-profile',
                method: 'POST',
                body,
            }),
        }),

        //updateProfile

        updateProfile: builder.mutation({
            query: (body) => ({
                url: `/users/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //createListing

        createListing: builder.mutation({
            query: (body) => ({
                url: '/property-listings',
                method: 'POST',
                body,
            }),
        }),

        //getListing

        getListing: builder.query({
            query: ({ page, pageSize }) => {
                const skip = (page - 1) * pageSize; // Calculate the skip value

                // Construct the URL with limit and skip for pagination, and other query parameters
                const queryParams = new URLSearchParams({
                    populate: 'created_by',
                    skip: skip, // Using skip for pagination
                    limit: pageSize,
                    sort: '-created_at',
                    deleted: 'false'
                }).toString();

                return {
                    url: `/property-listings?${queryParams}`,
                    method: 'GET'
                };
            }
        }),


        //get My Listings

        getMyListings: builder.query({
            query: (id) => ({
                url: `/property-listings?created_by=${id}&&deleted=false`,
                method: 'GET',
            }),
        }),

        updateListing: builder.mutation({
            query: (body) => ({
                url: `/property-listings/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        uploadListingImage: builder.mutation({
            query: (body) => ({
                url: `/upload/${body.listing}`,
                method: 'POST',
                body: body.formData
            }),
        }),

        //get property images

        getListingImages: builder.query({
            query: (id) => ({
                url: `/files/${id}`,
                method: 'GET',
            }),
        }),

        //delete property image

        deleteListingImage: builder.mutation({
            query: (filename) => ({
                url: `/delete-file/${filename}`,
                method: 'POST',
                body: {},
            }),
        }),

        //create listing interaction

        createListingInteraction: builder.mutation({
            query: (body) => ({
                url: '/property-interactions',
                method: 'POST',
                body,
            }),
        }),

        //count listings mutation

        countListings: builder.mutation({
            query: (filters) => ({
                url: '/count-listings',
                method: 'POST',
                body: filters,
            }),
        }),

        //filter listings

        filterListings: builder.mutation({
            query: (filters) => ({
                url: '/filter-property-listings',
                method: 'POST',
                body: filters,
            }),
        }),

    }),
});

export const {
    //auth endpoints
    useLoginMutation,
    useRegisterMutation,
    useForgotPasswordMutation,
    useChangePasswordMutation,
    //phone-search
    usePhoneSearchMutation,
    //complete-profile
    useCompleteProfileMutation,
    //update-profile
    useUpdateProfileMutation,
    //create-listing
    useCreateListingMutation,
    //get-listing
    useGetListingQuery,
    useUpdateListingMutation,
    useGetMyListingsQuery,
    useUploadListingImageMutation,
    useGetListingImagesQuery,
    useDeleteListingImageMutation,
    useCreateListingInteractionMutation,
    useCountListingsMutation,
    useFilterListingsMutation,
} = api;
