import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import Banner from '../../components/landing/Banner';
import FilterButtons from '../../components/landing/FilterButtons';
import Listings from '../../components/landing/Listings';
import { useSelector } from 'react-redux';
const Landing = () => {
  const filterState = useSelector(state => state.filter);
  const handleFilterPress = (filter) => {
    setSelectedFilter(filter);
    // Implement filter logic here
  };

  return (
    <View style={styles.container}>
      <Banner />
      <FilterButtons onFilterPress={handleFilterPress} />
      { !filterState.filterVisible&& <Listings/>}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#eee'
  },
});

export default Landing;
