import React, { useState, useEffect } from 'react';
import { View, Text, Linking, Button, TouchableOpacity, StyleSheet, CheckBox } from 'react-native';
import RoundedInput from '../../components/common/RoundedInput';
import RoundedButton from '../../components/common/RoundedButton';
import colors from '../../strings/colors';
import { useNavigation } from '@react-navigation/core';
import { useRegisterMutation } from '../../redux/api';
import Loader from '../../components/common/Loader';


import Terms from '../../components/common/Terms';
import { useSelector, useDispatch } from 'react-redux';
import Toast from 'react-native-toast-message';
import StayIcon from '../../components/common/StayIcon';
import { userActions } from '../../redux/userSlice';
import { companyActions } from '../../redux/companySlice';
import { HOME } from '../../config/constants/nav-routes.const';

const Register = () => {

  const user_state = useSelector(state => state.user);

  const dispatch = useDispatch();

  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [status, setStatus] = useState('');

  const [termsAccepted, setTermsAccepted] = useState(false);

  

  const [register, registerResult] = useRegisterMutation();

  const navigation = useNavigation();

  const handleNameChange = text => {
    setFirstName(text);
  }

  const handleLastNameChange = text => {
    setLastName(text);
  }

  const handleEmailChange = text => {
    setEmail(text);
  };

  const handleCellphoneChange = text => {
    setCellphone(text);
  };

  const handlePasswordChange = text => {
    setPassword(text);
  };

  const handleRegister = () => {
    
    //validate
    if (first_name === '') {
      Toast.show({
          type:'error',
          text1:'Validation Error',
          text2:'Please enter your first name'
      });
      return;
    }

    if (last_name === '') {
      Toast.show({
          type:'error',
          text1:'Validation Error',
          text2:'Please enter your last name'
      });
      return;
    }
  
    if (email === '') {
      Toast.show({
          type:'error',
          text1:'Validation Error',
          text2:'Please enter your email'
      });
      return;
    }
    if (password === '') {
      Toast.show({
          type:'error',
          text1:'Validation Error',
          text2:'Please enter your password'
      });
      return;
    }
    register({
      _id: user_state._id,
      username: `${first_name} ${last_name}`,
      cellphone: cellphone,
      email: email,
      password: password,
    });
  };

 
  useEffect(() => {
    if (registerResult.isSuccess) {
      setStatus(registerResult.data.message);
      if (registerResult.data.success === true) {
        // Navigate to a success page or handle accordingly
        dispatch(userActions.setUser(registerResult.data.user));
        //dispatch(companyActions.setCompany(registerResult.data.company));
        navigation.navigate(HOME);
      }else{
        Toast.show({
            type:'error',
            text1:'Error',
            text2:registerResult.data.message
        });
      }

    }
    if (registerResult.isError) {
      Toast.show({
          type:'error',
          text1:'Error',
          text2:registerResult.error.data.message
      });
      console.log(registerResult.error);
    }
  }, [registerResult.isLoading]);

  if (registerResult.isLoading) {
    return (
      <Loader message='Registering...' />
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.layout}>
        <StayIcon title={'Register'} description={'Create A New Account'}/>
        <RoundedInput placeholder="Your First Name" onChangeText={handleNameChange} />
        <RoundedInput placeholder="Your Last Name" onChangeText={handleLastNameChange} />
        <RoundedInput placeholder="Your Cellphone" keyboardType='phone-pad' onChangeText={handleCellphoneChange} />
        <RoundedInput placeholder="Your Email" keyboardType='email-address' onChangeText={handleEmailChange} />
        <RoundedInput placeholder="Create New Password" keyboardType='password' onChangeText={handlePasswordChange} isPassword={true} />
        <Text style={styles.statusText}>{status}</Text>
        <Terms onTermsAccepted={setTermsAccepted} />
        <RoundedButton title="REGISTER" onPress={handleRegister} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  layout: {
    width: '90%',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
  },
  statusText: {
    color: colors.orange,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
  },
  checkboxText: {
    marginLeft: 10,
  },
  linkText: {
    color: 'blue',
    textDecorationLine: 'underline',
  },

});

export default Register;
