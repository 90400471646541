/**
 * TownSelector.jsx
 *
 * @author Immanuel N Fransiskus
 * @copyright 2024
 * All rights reserved
 */

import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { MultipleSelectList } from 'react-native-dropdown-select-list';
import { TOWNS } from '../../config/enums/towns.enum';
import { useDispatch } from 'react-redux';
import { filterActions } from '../../redux/filterSlice';
import colors from '../../strings/colors';

const TownSelector = () => {
    const [selectedTowns, setSelectedTowns] = useState([]);
    const dispatch = useDispatch();

    const townData = TOWNS.map((town, index) => ({ key: index.toString(), value: town }));

    return (
        <View style={styles.container}>
            <MultipleSelectList
                setSelected={setSelectedTowns}
                data={townData}
                placeholder="Search By Town"
                save="value"
                label="Towns"
                onSelect={() => dispatch(filterActions.setLocations(selectedTowns))}
                inputStyles={{
                    fontFamily: 'Poppins', 
                    color: colors.black, 
                    width: '100%',
                    fontSize: 16, 
                    borderRadius: 25, 
                    borderColor: 'transparent', 
                    borderWidth: 0
                }}
                labelStyles={styles.labelStyle}
                boxStyles={styles.dropdown}
                dropdownTextStyles={styles.dropdownText}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        marginTop: 20,
        width: '100%',
    },
    labelStyle: {
        fontSize: 16,
        color: colors.black,
        fontFamily: 'Poppins'
    },
    dropdown: {
        borderWidth: 1,
        borderColor: '#eee',
        backgroundColor: '#eee',
        width: "100%",
        borderRadius: 25,
        paddingVertical: 8,
        paddingHorizontal: 10,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 15,
    },
    dropdownText: {
        color: colors.black,
        fontFamily: 'Poppins',
        flex: 1,
        paddingVertical: 12,
        borderColor: 'transparent',
        borderWidth: 0,
    },
});

export default TownSelector;
