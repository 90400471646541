/**
 * filters.enum.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import { FOR_RENT, FOR_SALE, PROPERTY_STATUSES } from "./property-statuses.enum";
import { PROPERTY_TYPES } from "./property-types.enum";

const ALL = "All";

const POPULAR = "Popular";
const RECENT = "Recent";


const FEED_FILTERS = [ALL, POPULAR, RECENT];

const ALL_TIME = "All Time";
const TODAY = "Today";
const YESTERDAY = "Yesterday";
const THIS_WEEK = "This Week";
const THIS_MONTH = "This Month";
const THIS_YEAR = "This Year";
const CUSTOM = "Custom";

const LISTED_PERIODS_FILTERS = [ ALL_TIME, TODAY, YESTERDAY, THIS_WEEK, THIS_MONTH, THIS_YEAR, CUSTOM ];

const LOW_TO_HIGH = "Low to High";
const HIGH_TO_LOW = "High to Low";

const PRICING_ORDERS_FILTERS = [ LOW_TO_HIGH, HIGH_TO_LOW ];

const LISTING_STATUSES_FILTERS = [ALL,FOR_RENT,FOR_SALE];

const LISTING_TYPES_FILTERS = [ALL, ...PROPERTY_TYPES];

export {
    FEED_FILTERS,
    LISTED_PERIODS_FILTERS,
    PRICING_ORDERS_FILTERS,
    LISTING_STATUSES_FILTERS,
    LISTING_TYPES_FILTERS,
    ALL,
    POPULAR,
    RECENT,
    ALL_TIME,
    TODAY,
    YESTERDAY,
    THIS_WEEK,
    THIS_MONTH,
    THIS_YEAR,
    CUSTOM,
    LOW_TO_HIGH,
    HIGH_TO_LOW
};