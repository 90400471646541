/**
 * Banner.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import React from 'react';
import { View, Image, StyleSheet, Text, Dimensions } from 'react-native';
const stayBanner = require('../../../assets/images/banner.jpg');


const screenWidth = Dimensions.get('window').width;

const Banner = () => {

  console.log('Banner width', screenWidth);
  return (
    <View style={styles.bannerContainer}>
      <Image
        source={stayBanner} // Replace with your banner image URL
        style={styles.bannerImage}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  bannerContainer: {
    width: '100%',
    height: screenWidth>700?600:200, // Adjust the height of the banner 
    marginBottom: 10,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  }
});

export default Banner;
