/**
 * DashboardStats.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { FOR_RENT, FOR_SALE, RENTED, SOLD } from '../../config/enums/property-statuses.enum';
import colors from '../../strings/colors';

const DashboardStats = ({ listings, onCreateNewListing }) => {
  const totalListings = listings.length;
  const forRent = listings.filter(listing => listing.propertyStatus === FOR_RENT).length;
  const forSale = listings.filter(listing => listing.propertyStatus === FOR_SALE).length;
  const rented = listings.filter(listing => listing.status === RENTED).length;
  const sold = listings.filter(listing => listing.status === SOLD).length;
  const deleted = listings.filter(listing => listing.deleted).length;

  return (
    <>
   
    <View style={styles.container}>
      <StatItem label="All Listings" value={totalListings} />
      <StatItem label="For Rent" value={forRent} />
      <StatItem label="For Sale" value={forSale} />
      <StatItem label="Rented" value={rented} />
      <StatItem label="Sold" value={sold} />
      <StatItem label="Deleted" value={deleted} />
      
    </View>
    <TouchableOpacity style={styles.button} onPress={onCreateNewListing}>
        <Text style={styles.buttonText}>Create New Listing</Text>
      </TouchableOpacity>
      
 </>
  );
};

const StatItem = ({ label, value }) => (
  <View style={styles.statItem}>
    <Text style={styles.statValue}>{value}</Text>
    <Text style={styles.statLabel}>{label}</Text>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 20,
    backgroundColor: '#f5f5f5',
  },
  statItem: {
    minWidth: 90,
    margin: 10,
    backgroundColor: colors.white,
    borderRadius: 20,
    alignItems: 'center',
    elevation: 3, // Shadow for Android
    shadowOffset: { width: 1, height: 1 }, // Shadow for iOS
    shadowColor: '#333',
    shadowOpacity: 0.3,
    shadowRadius: 2,
    padding: 10
    
  },
  statValue: {
    fontSize: 22,
    fontWeight: 'bold',
    color: '#333',
    fontFamily:'Poppins-Bold',
  },
  statLabel: {
    fontSize: 14,
    color: '#666',
  },
  button: {
    backgroundColor: '#007bff',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 20,
    marginTop: 5,
    marginBottom: 20,
    width: '50%',
    alignSelf: 'center',
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
  }
});

export default DashboardStats;
