import React, { useState } from 'react';
import { View, Text, Image, StyleSheet, ActivityIndicator } from 'react-native';
import colors from '../../strings/colors';

const ProfilePic = ({ url, width, username }) => {

   
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const handleImageError = () => {
    setIsLoading(false);
    setHasError(true);
  };

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <View style={[styles.imageContainer, { width: width, height: width, borderRadius: width / 2 }]}>
      {isLoading && (
        <View style={styles.loaderContainer}>
          <ActivityIndicator size="small" color={colors.white} />
        </View>
      )}
      {!hasError && (
        <Image
          source={{ uri: url }}
          style={{ width: width, height: width, borderRadius: width / 2 }}
          onError={handleImageError}
          onLoad={handleImageLoad}
        />
      )}
      {hasError && (
        <Text style={[styles.fallbackText, { fontSize: width / 2 }]}>{username.charAt(0).toUpperCase()}</Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  imageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundColor: '#007bff',
  },
  loaderContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  fallbackText: {
    color: 'white',
    fontWeight: 'bold',
  },
});

export default ProfilePic;
