import React, { useRef, useEffect, useState } from 'react';
import { View, TouchableOpacity, Text, StyleSheet, Animated, Platform } from 'react-native';
import colors from '../../strings/colors';
import { Picker } from '@react-native-picker/picker';
import { useSelector, useDispatch } from 'react-redux';
import {
    FEED_FILTERS,
    LISTED_PERIODS_FILTERS,
    PRICING_ORDERS_FILTERS,
    LISTING_STATUSES_FILTERS,
    LISTING_TYPES_FILTERS,
    ALL
} from '../../config/enums/filters.enum';
import { filterActions } from '../../redux/filterSlice';
import PriceRangePicker from './PriceRangePicker';
import ListingTypeCheckboxes from './ListingTypeDropdown';
import TownSelector from './TownSelector';
import { toCurrency } from '../../utils/app_utils';
import PriceRangeDropdown from './PriceRangeDropdown';
import BedroomsBathroomsDropdown from './BedroomsBathroomsDropdown';
import RoundedButton from '../common/RoundedButton';
import { useCountListingsMutation, useFilterListingsMutation } from '../../redux/api';
import { listingsActions } from '../../redux/listingsSlice';

const FilterButtons = () => {
    const filterState = useSelector(state => state.filter);

    const dispatch = useDispatch();

    const [listingCount, setListingCount] = useState(0);

    const marginTopAnim = useRef(new Animated.Value(-30)).current;

    const onListingStatusPress = (status) => {
        dispatch(filterActions.setListingStatus(status));
    }

    const [countListings, countListingsResult] = useCountListingsMutation();

    const [filterListings, filterListingsResult] = useFilterListingsMutation();

    //cont listings

    const {
        locations,
        price_low,
        price_high,
        listingStatus,
        lsitingType,
        bathrooms,
        bedrooms,
    } = filterState;
 

    const handleFilterPress = () => {
        dispatch(filterActions.setFilterVisible(true));
        dispatch(listingsActions.setLoading(true));

        const query = {};

        if (locations.length > 0) query.towns = locations;
        if (price_low > 0) query.price_low = price_low;
        if (price_high > 0 && price_high !== 1000000) query.price_high = price_high; // Assuming 1000000 is a default or max value
        if (bedrooms > 0) query.bedrooms = bedrooms;
        if (bathrooms > 0) query.bathrooms = bathrooms;
        if (lsitingType !== ALL) query.type = lsitingType;
        if (listingStatus !== ALL) query.status = listingStatus;

        filterListings(query);
    };



    useEffect(() => {
        const query = {};
    
        if (locations.length > 0) query.towns = locations;
        if (price_low > 0) query.price_low = price_low;
        if (price_high > 0 && price_high !== 1000000) query.price_high = price_high; // Assuming 1000000 is a default or max value
        if (bedrooms > 0) query.bedrooms = bedrooms;
        if (bathrooms > 0) query.bathrooms = bathrooms;
        if (lsitingType !== ALL) query.type = lsitingType;
        if (listingStatus !== ALL) query.status = listingStatus;
    
        countListings(query);

    }, [filterState]);

    useEffect(() => {

        if (countListingsResult.isSuccess) {
            setListingCount(countListingsResult.data.count);
        }

    }, [countListingsResult.isLoading]);

    useEffect(() => {
        if (filterListingsResult.isSuccess) {
            dispatch(listingsActions.setListings(filterListingsResult.data));
            dispatch(listingsActions.setLoading(false));

            dispatch(filterActions.setFilterVisible(false));
        }

    }, [filterListingsResult.isLoading]);
    

    useEffect(() => {
        Animated.spring(marginTopAnim, {
            toValue: -30,
            useNativeDriver: false,
        }).start();
    }, [filterState.filterVisible, marginTopAnim]);

    if (!filterState.filterVisible) return null;

    return (

        <Animated.View style={[styles.mainContainer, { marginTop: marginTopAnim }]}>
            <View style={styles.filtersContainer}>
                {LISTING_STATUSES_FILTERS.map((status, index) => (
                    <TouchableOpacity
                        key={`status-${index}`}
                        style={[styles.filterButton, {
                            backgroundColor: status === filterState.listingStatus ? colors.stay_blue : "#eee"
                        }]}
                        onPress={() => onListingStatusPress(status)}
                    >
                        <Text style={[styles.filterText, {
                            color: status === filterState.listingStatus ? colors.white : colors.black
                        }]}>{status}</Text>
                    </TouchableOpacity>
                ))}
            </View>

            <TownSelector />

            <ListingTypeCheckboxes />

            <PriceRangeDropdown/>

            <BedroomsBathroomsDropdown/>

            <RoundedButton
                title={`Apply Filters (${listingCount})`}
                loading={filterListingsResult.isLoading || countListingsResult.isLoading}
                onPress={handleFilterPress}
                style={{ backgroundColor: colors.stay_blue, width: '100%', marginTop: 10 }}
            />

        </Animated.View>

    );
};

const styles = StyleSheet.create({
    mainContainer: {
        flexDirection: 'column',
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '95%',
        maxWidth: 700,
        backgroundColor: colors.white,
        borderRadius: 30,
        marginBottom: 10,
        elevation: 3, // Shadow for Android
        shadowOffset: { width: 1, height: 1 }, // Shadow for iOS
        shadowColor: '#333',
        shadowOpacity: 0.1,
        shadowRadius: 1,
    },
    filtersContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        borderRadius: 30,
    },

    filterButton: {
        borderRadius: 20,
        padding: 10,
        paddingHorizontal: 20,
        margin: 1,
        backgroundColor: "#eee",
        elevation: 3, // Shadow for Android
        shadowOffset: { width: 1, height: 1 }, // Shadow for iOS
        shadowColor: '#333',
        shadowOpacity: 0.1,
        shadowRadius: 1,
    },
    filterText: {
        color: colors.black,
        fontFamily: 'Poppins',
    },

    filterLable: {
        marginLeft: 10,
        marginTop: 10,
        marginBottom: 5,
        fontWeight: 'bold',
        fontFamily: 'Poppins-Bold',
        color: colors.light_gray
    },
    // ... other styles ...
});

export default FilterButtons;
