/**
 * listingSlice.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import { createSlice } from '@reduxjs/toolkit';
import { HOUSE } from '../config/enums/property-types.enum';
import { SQUARE_METER } from '../config/enums/size-units.enum';
import { FOR_RENT } from '../config/enums/property-statuses.enum';

export const listingSlice = createSlice({
    name: 'listing',
    initialState: {
        _id: '',
        name: '',
        description: '',
        suburb: '',
        town: '',
        size: 0,
        sizeUnit: SQUARE_METER.value,
        numberOfRooms: 1,
        numberOfBathrooms: 0,
        numberOfParking: 0, 
        numberOfGarages: 0,
        garden: false,
        petsAllowed: false,
        propertyType: HOUSE,
        propertyStatus: FOR_RENT,
        propertyRent: 0,
        propertyDeposit: 0,
        purchaseAmount: 0,
        created_by: '', 
        created_at: '',
        deleted: false,
        images: []
    },

    reducers: {

        setListing: (state, action) => {
            state._id = action.payload._id
            state.name = action.payload.name
            state.description = action.payload.description
            state.suburb = action.payload.suburb
            state.town = action.payload.town
            state.size = action.payload.size
            state.sizeUnit = action.payload.sizeUnit
            state.numberOfRooms = action.payload.numberOfRooms
            state.numberOfBathrooms = action.payload.numberOfBathrooms
            state.numberOfParking = action.payload.numberOfParking
            state.numberOfGarages = action.payload.numberOfGarages
            state.garden = action.payload.garden
            state.petsAllowed = action.payload.petsAllowed
            state.propertyType = action.payload.propertyType
            state.propertyStatus = action.payload.propertyStatus
            state.propertyRent = action.payload.propertyRent
            state.propertyDeposit = action.payload.propertyDeposit
            state.purchaseAmount = action.payload.purchaseAmount
            state.created_by = action.payload.created_by
            state.created_at = action.payload.created_at
        },

        setId: (state, action) => {
            state._id = action.payload
        },

        setName: (state, action) => {
            state.name = action.payload
        },
        setDescription: (state, action) => {
            state.description = action.payload
        },
        setSuburb: (state, action) => {
            state.suburb = action.payload
        },
        setTown: (state, action) => {
            state.town = action.payload
        },
        setSize: (state, action) => {
            state.size = action.payload
        },
        setSizeUnit: (state, action) => {
            state.sizeUnit = action.payload
        },
        setNumberOfRooms: (state, action) => {
            state.numberOfRooms = action.payload
        },
        setNumberOfBathrooms: (state, action) => {
            state.numberOfBathrooms = action.payload
        },
        setNumberOfParking: (state, action) => {
            state.numberOfParking = action.payload
        },
        setNumberOfGarages: (state, action) => {
            state.numberOfGarages = action.payload
        },
        setGarden: (state, action) => {
            state.garden = action.payload
        },
        setPetsAllowed: (state, action) => {
            state.petsAllowed = action.payload
        },
        setPropertyType: (state, action) => {
            state.propertyType = action.payload
        },
        setPropertyStatus: (state, action) => {
            state.propertyStatus = action.payload
        },
        setPropertyRent: (state, action) => {
            state.propertyRent = action.payload
        },
        setPropertyDeposit: (state, action) => {
            state.propertyDeposit = action.payload
        },
        setPurchaseAmount: (state, action) => {
            state.purchaseAmount = action.payload
        },
        setCreatedBy: (state, action) => {
            state.created_by = action.payload
        },
        setCreatedAt: (state, action) => {
            state.created_at = action.payload
        },

        setDeleted: (state, action) => {
            state.deleted = action.payload
        },

        setImage: (state, action) => {
            state.images.push(action.payload)
        },

        removeImage: (state, action) => {
            state.images = state.images.filter((img) => img !== action.payload)
        },

        clearImages: (state) => {
            state.images = []
        },



        clearListing: (state) => {
            state._id = ''
            state.name = ''
            state.description = ''
            state.suburb = ''
            state.town = ''
            state.size = 0
            state.sizeUnit = SQUARE_METER.value
            state.numberOfRooms = 0
            state.numberOfBathrooms = 0
            state.numberOfParking = 0
            state.numberOfGarages = 0
            state.garden = false
            state.petsAllowed = false
            state.propertyType = HOUSE
            state.propertyStatus = FOR_RENT
            state.propertyRent = 0
            state.propertyDeposit = 0
            state.purchaseAmount = 0
            state.created_by = ''
            state.created_at = '',
            state.deleted = false
            state.images = []
        },
    }
})

export const listingActions = listingSlice.actions;