/**
 * towns.enum.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

//namibian towns

const TOWNS = [
    'Windhoek',
    'Swakopmund',
    'Walvis Bay',
    'Luderitz',
    'Oshakati',
    'Otjiwarongo',
    'Katima Mulilo',
    'Grootfontein',
    'Rehoboth',
    'Tsumeb',
    'Keetmanshoop',
    'Rundu',
    'Okahandja',
    'Ongwediva',
    'Ondangwa',
    'Mariental',
    'Outjo',
    'Usakos',
    'Karibib',
    'Eenhana',
    'Opuwo',
    'Henties Bay',
    'Oshikango',
    'Omaruru',
    'Otavi',
    'Gobabis',
    'Karasburg',
]

export {TOWNS};