/**
 * MyListings.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import React, { useEffect, useState } from 'react';
import { FlatList, View, StyleSheet } from 'react-native';
import { useGetMyListingsQuery, useUpdateListingMutation } from '../../redux/api';
import { useSelector, useDispatch } from 'react-redux';
import { listingActions } from '../../redux/listingSlice';
import { useNavigation } from '@react-navigation/native';
import MyListing from '../../components/stay/MyListing';
import Loader from '../../components/common/Loader';
import DashboardStats from '../../components/stay/DashboardStats';
import Toast from 'react-native-toast-message';

const MyListings = () => {

  const user_state = useSelector(state => state.user); // Get user state

  const [loadingMessage, setLoadingMessage] = useState('Loading your listings...');

  const navigation = useNavigation();

  const dispatch = useDispatch();
   
  const {data: myListings, isLoading, refetch, isFetching} = useGetMyListingsQuery(user_state._id); // Fetch my listings

  const [updatedListing, updateListingResult] = useUpdateListingMutation();

  const handleEdit = (listing) => {
     dispatch(listingActions.setListing(listing));
     dispatch(listingActions.clearImages());
     navigation.navigate('NewListing');
  };

  const handleView = (listing) => {
    dispatch(listingActions.setListing(listing));
    dispatch(listingActions.clearImages());
    navigation.navigate('ViewListing');
 };

  const handleDelete = (listing) => {
    setLoadingMessage('Deleting listing...');
    updatedListing({_id: listing._id, deleted: true})
  };

  const handleCreateNewListing = () => {
    dispatch(listingActions.clearListing());
    navigation.navigate('NewListing');

  };


  useEffect(()=>{
      if(updateListingResult.isSuccess){
        Toast.show({
            type: 'success',
            text1: 'Success',
            text2: 'Listing deleted successfully',
        })
        setLoadingMessage('Loading your listings...');
        refetch();
      }

        if(updateListingResult.isError){
            console.log(updateListingResult.error);
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: updateListingResult.error.data.message || 'An error occurred',
            })
        }

  },[updateListingResult.isLoading])
  

  if(isFetching || isLoading || updateListingResult.isLoading){
    return <Loader message={loadingMessage}/>
  }

  return (
    <View style={styles.container}>
      {myListings && <DashboardStats listings={myListings} onCreateNewListing={handleCreateNewListing}/>}
      <FlatList
        data={myListings}
        renderItem={({ item }) => (
          <MyListing
            listing={item}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onView={handleView}
          />
        )}
        keyExtractor={item => item._id}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  
});

export default MyListings;
