/**
 * nav-routes.const.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

const HOME =  'Home';
const NEW_LISTING = 'NewListing';
const VIEW_LISTING = 'ViewListing';
const MY_LISTINGS = 'MyListings';
const PROFILE   = 'Profile';
const LOGIN = 'Login';
const REGISTER = 'Register';
const CHANGE_PASSWORD = 'ChangePassword';
const RESET_PASSWORD = 'ResetPassword';
const ACCOUNT = 'Account';

export {
    HOME,
    NEW_LISTING,
    VIEW_LISTING,
    MY_LISTINGS,
    PROFILE,
    LOGIN,
    REGISTER,
    CHANGE_PASSWORD,
    RESET_PASSWORD,
    ACCOUNT
}