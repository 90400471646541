import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import colors from '../../strings/colors';
import { filterActions } from '../../redux/filterSlice';
import { LISTING_TYPES_FILTERS } from '../../config/enums/filters.enum';
import StayDropDown from '../common/StayDropDown';

const ListingTypeDropdown = () => {
    const filterState = useSelector(state => state.filter);
    const dispatch = useDispatch();

    const data = LISTING_TYPES_FILTERS.map(type => ({key: type, value: type}));

    return (
        <View style={styles.container}>
            <StayDropDown
                data={data}
                placeholder="Property Type"
                onSelectedItem={selectedItem => dispatch(filterActions.setListingType(selectedItem))}
                defaultOption={filterState.listingType}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: '100%',
    },
});

export default ListingTypeDropdown;
