/**
 * ViewListing.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */
import React, { useEffect, useState,useCallback } from 'react';
import { StatusBar, ScrollView, View, Text, Image, Dimensions, StyleSheet,ActivityIndicator, TouchableOpacity, Linking } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import Agent from '../../components/stay/Agent';
import colors from '../../strings/colors';
import { useSelector, useDispatch } from 'react-redux';
import { FOR_RENT } from '../../config/enums/property-statuses.enum';
import { formatCellphoneNumber, toCurrency } from '../../utils/app_utils';
import { useCreateListingInteractionMutation, useGetListingImagesQuery } from '../../redux/api';
import { EXPO_PUBLIC_API_ENDPOINT } from '../../config/app.conf';
import { listingActions } from '../../redux/listingSlice';
import Loader from '../../components/common/Loader';
import { GALLERY, THUMBNAIL } from '../../config/constants/image-prefixes.const';
import GalleryModal from './GalleryModal';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Toast from 'react-native-toast-message';
import { INTERESTED } from '../../config/enums/interaction-types.enum';

const screenWidth = Dimensions.get('window').width;

const ViewListing = () => {

  const dispatch = useDispatch();

  const listing = useSelector(state => state.listing);

  const user = useSelector(state => state.user);

  const [galleryImages, setGalleryImages] = useState([]);

  const [galleryVisible, setGalleryVisible] = useState(false);

  const [interact,interctionResult] = useCreateListingInteractionMutation();


  const { data: apiImages, error: apiError, isLoading: apiLoading, isSuccess } = useGetListingImagesQuery(listing._id);
  const [imageLoadings, setImageLoadings] = useState(() => listing.images.map(() => true));
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openGallery = () => setGalleryVisible(true);
  const closeGallery = () => setGalleryVisible(false);

  const handleScroll = (event) => {
    const contentOffsetX = event.nativeEvent.contentOffset.x;
    const currentIndex = Math.round(contentOffsetX / screenWidth);
    setCurrentImageIndex(currentIndex);
  };

  const handleImageLoaded = (index) => {
    setImageLoadings(prev => prev.map((loading, i) => i === index ? false : loading));
  };


  

  const handleWhatsAppPress = useCallback(()=>{
    const agentPhone = listing.created_by.cellphone; // Replace with actual path to agent's phone number

    const message = `Hello ${listing.created_by.username} 👋! I came across '${listing.name}' on the Stay App and it really caught my eye! Is it still available? 🏠`


    let whatsappUrl = `whatsapp://send?text=${encodeURIComponent(message)}&phone=${formatCellphoneNumber(agentPhone)}`;

    

    console.log(whatsappUrl);

    Linking.openURL(whatsappUrl).catch(() => {
      Toast.show({
        type: 'error',
        text1: 'Error',
        text2: 'Please install WhatsApp to use this feature',
        position: 'top',
      });
    });

    let interaction = {
      listing:listing._id,
      interaction_type:INTERESTED
    }

    if(user._id!==''){
      interaction = {created_by:user._id,...interaction}
    }

    interact({
      listing: listing._id,
      interaction_type: INTERESTED,

    });

  }, [listing.created_by.cellphone, listing.name]);


  const renderDetailItem = (iconName, detailText) => (
    <View style={styles.detailItem}>
      <MaterialIcons name={iconName} size={20} color={colors.gray} />
      <Text style={styles.detailText}>{detailText}</Text>
    </View>
  );

  useEffect(() => {

    if (isSuccess) {
      dispatch(listingActions.clearImages())

      const thumbnail_images = apiImages.filter(image => image.filename.includes(THUMBNAIL));

      const high_res_images = apiImages.filter(image => image.filename.includes(GALLERY));

      for (let i = 0; i < high_res_images.length; i++) {
        setGalleryImages(prev => [...prev, `${EXPO_PUBLIC_API_ENDPOINT}/stream/${high_res_images[i].filename}`]);
      }

      for (let i = 0; i < thumbnail_images.length; i++) {

        dispatch(listingActions.setImage(`${EXPO_PUBLIC_API_ENDPOINT}/stream/${thumbnail_images[i].filename}`));
      }

    }

    if (apiError) {
      console.log(apiError);
    }

  }, [apiLoading])

  if (apiLoading) {
    return <Loader message='Loading Listing Images...' />
  }

  return (
    <>
      <StatusBar barStyle="light-content" translucent backgroundColor="transparent" />
      <ScrollView style={styles.container}>
        {/* Image Slider */}
        <ScrollView
          horizontal
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          onScroll={handleScroll}
          scrollEventThrottle={16}
        >
          {listing.images.map((image, index) => (
            <TouchableOpacity key={index} style={styles.imageWrapper} onPress={openGallery} >
              {imageLoadings[index] && <ActivityIndicator style={{marginTop:100}} size="large" color={colors.stay_blue} />}
              <Image
                source={{ uri: image }}
                style={[styles.image,{height: imageLoadings[index] ? 0 : 250}]}
                onLoad={() => handleImageLoaded(index)}
              />
            </TouchableOpacity>
          ))}
        </ScrollView>
        <View style={styles.indicatorContainer}>
          {listing.images.map((_, index) => (
            <View
              key={index}
              style={[
                styles.indicator,
                currentImageIndex === index ? styles.indicatorActive : null,
              ]}
            />
          ))}
        </View>

        {/* Property Title, Description, and Details */}
        <View style={styles.details}>
          <Text style={styles.title}>{listing.name}</Text>
          <Text style={styles.description}>{listing.description}</Text>

          {/* Price and Address */}
          {listing.propertyStatus === FOR_RENT ?
            <Text style={styles.price}>N$ {toCurrency(listing.propertyRent)} - {`(${listing.propertyStatus})`}</Text> :
            <Text style={styles.price}>N$ {toCurrency(listing.purchaseAmount)} - {`(${listing.propertyStatus})`}</Text>}
          <View style={styles.addressContainer}>
            <MaterialIcons name="place" size={20} color={colors.gray} />
            <Text style={styles.address}>{listing.town}, {listing.suburb}</Text>
          </View>

          {/* Additional Details */}
          {renderDetailItem("aspect-ratio", `${listing.size} ${listing.sizeUnit}`)}
          {renderDetailItem("hotel", `${listing.numberOfRooms} Bedrooms`)}
          {renderDetailItem("bathtub", `${listing.numberOfBathrooms} Bathrooms`)}
          {renderDetailItem("pets", listing.petsAllowed ? "Pets Allowed" : "No Pets")}
          {renderDetailItem("directions-car", `${listing.numberOfParking} Parking`)}
          {renderDetailItem("house", `${listing.numberOfGarages} Garage`)}
          {renderDetailItem("grass", listing.garden ? "Garden" : "No Garden")}
        </View>
        <TouchableOpacity style={styles.whatsappButton} onPress={handleWhatsAppPress}>
        <MaterialCommunityIcons name="whatsapp" size={24} color="white" />
        <Text style={styles.whatsappButtonText}>Interested</Text>
      </TouchableOpacity>
        {/* Agent Details */}
        {listing.created_by.username && <Agent agent={listing.created_by} />}
      </ScrollView>
      <GalleryModal
        visible={galleryVisible}
        images={galleryImages}
        onRequestClose={closeGallery}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  image: {
    width: screenWidth,
    height: 300,
    resizeMode: 'cover',
    borderBottomLeftRadius: 40,
    borderBottomRightRadius: 40,
  },
  details: {
    padding: 30,
    backgroundColor: colors.white,
    borderRadius: 30,
    margin: 3,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    fontFamily: 'Poppins-Bold',
  },
  description: {
    fontSize: 16,
    color: 'gray',
    fontFamily: 'Poppins',
  },
  price: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'green',
    marginTop: 10,
  },
  addressContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  address: {
    marginLeft: 5,
    fontSize: 16,
    color: 'gray',
  },
  detailItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
  },
  detailText: {
    marginLeft: 5,
    fontSize: 16,
    color: 'gray',
  },
  indicatorContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 10,
  },
  indicator: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: 'lightgray',
    marginHorizontal: 5,
  },
  indicatorActive: {
    backgroundColor: 'gray',
  },
  whatsappButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#25D366', // WhatsApp color
    padding: 10,
    borderRadius: 30,
    marginTop: 10,
    alignSelf: 'center',
  },
  whatsappButtonText: {
    color: 'white',
    marginLeft: 8,
    fontWeight: 'bold',
  },
});

export default ViewListing;
