import React from 'react';
import { View, TouchableOpacity, Text, StyleSheet, Modal, Linking, Platform } from 'react-native';
import MenuItem from './MenuItem';
import { useNavigation } from '@react-navigation/core';
import { useDispatch } from 'react-redux';
import { userActions } from '../../redux/userSlice';
import colors from '../../strings/colors';
import Toast from 'react-native-toast-message';
import { ACCOUNT, CHANGE_PASSWORD, LOGIN, MY_LISTINGS, PROFILE } from '../../config/constants/nav-routes.const';


const MenuPopup = ({ visible, close }) => {

  const navigation = useNavigation();

  const dispatch = useDispatch();

  const menuItems = [
    { icon: 'person-outline', label: 'Profile' },
    { icon: 'settings-outline', label: 'Account' },
    { icon: 'lock-open-outline', label: 'Change Password' },
    { icon: 'business-outline', label: 'My Listings' },
    { icon: 'bookmark-outline', label: 'Terms' },
    { icon: 'log-out-outline', label: 'Logout' },
  ];

  //handle item click

  const handleItemClick = (label) => {
      switch(label){
          case 'Account':
              navigation.navigate(ACCOUNT);
              close();
              break;
          case 'Profile':
              navigation.navigate(PROFILE);
              close();
              break;
          case 'Change Password':
              navigation.navigate(CHANGE_PASSWORD);
              close();
              break;
          case 'Invite & Earn':
              const affliaiteUrl = 'https://affiliate.siskus.com';
              if (Platform.OS ==='web'){
                Linking.openURL(affliaiteUrl);
              }else{
                navigation.navigate('MyWebView', { url: affliaiteUrl });
              }
              
              close();
              break;
          case 'My Listings':
              navigation.navigate(MY_LISTINGS);
              close();
              break;
          case 'How It Works':
              if(Platform.OS ==='web'){
                Linking.openURL('https://siskus.com#start');
              }else{
                navigation.navigate('MyWebView', { url: 'https://siskus.com#start' });
              }
             
              close();
              break;
          case 'Contact Us':
              const phoneNumber = '+264818210855'; // Replace with the phone number you want to call
              Linking.openURL(`tel:${phoneNumber}`);
              close();
              break;
          case 'Terms':
              
              close();

              break;
          case 'Logout':
              dispatch(userActions.removeUser());
              navigation.navigate(LOGIN);
              close();
              break;
      }
  };

  return (
    <Modal
      animationType='slide'
      transparent={true}
      visible={visible}
      onRequestClose={close}
    >
      <TouchableOpacity style={styles.background} onPress={close}>
        <View style={styles.menu} onPress={close}>
          <View style={styles.menuItemsContainer}>
            {menuItems.map((item, index) => (
              <MenuItem key={index} icon={item.icon} label={item.label} onPress={()=>handleItemClick(item.label)} />
            ))}
          </View>
        </View>
      </TouchableOpacity>
    </Modal>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menu: {
    backgroundColor: colors.white,
    margin: 20,
    borderRadius: 20,
    padding: 20,
    alignItems: 'center',
   
  },
  
  menuItemsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default MenuPopup;
