/**
 * AddListingButton.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import React from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { EXPO_PUBLIC_APP_ENV } from '../../config/app.conf';
import { useSelector, useDispatch } from 'react-redux';
import { listingActions } from '../../redux/listingSlice';

const AddListingButton = () => {
    const userState = useSelector(state => state.user);
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const handlePress = () => {
     
        if(!userState._id){
            return navigation.navigate('Login');
        }
        dispatch(listingActions.clearListing())
        navigation.navigate('NewListing');
    };

  return (
    <TouchableOpacity style={styles.button} onPress={handlePress}>
      <MaterialIcons name="add-business" size={22} color="white" />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#007bff', // Customize your button color
    height: 36,
    width:36,
    marginTop:5,
    borderRadius: 50, // Adjust for circular shape
    elevation: 3, // Shadow for Android
    shadowOffset: { width: 1, height: 1 }, // Shadow for iOS
    shadowColor: '#333',
    shadowOpacity: 0.3,
    shadowRadius: 2,
    marginRight:10,
   
  },
});

export default AddListingButton;
