/**
 * BedroomsBathroomsDropdown.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import colors from '../../strings/colors';
import { filterActions } from '../../redux/filterSlice';
import StayDropDown from '../common/StayDropDown';

const BEDROOMS_OPTIONS = [
    { key: 1, value: '1+' },
    { key: 2, value: '2+' },
    { key: 3, value: '3+' },
    { key: 4, value: '4+' },
    { key: 5, value: '5+' },
    // Add more bedroom options as needed
];

const BATHROOMS_OPTIONS = [
    { key: 0, value: '0' },
    { key: 1, value: '1+' },
    { key: 2, value: '2+' },
    { key: 3, value: '3+' },
    { key: 4, value: '4+' },
    { key: 5, value: '5+' },
    // Add more bathroom options as needed
];

const BedroomsBathroomsDropdown = () => {
    const filterState = useSelector(state => state.filter);
    const dispatch = useDispatch();

    return (
        <View style={styles.container}>
            <View style={styles.dropdownWrapper}>
                <View style={styles.dropdown}>
                    <Text style={styles.label}>Bedrooms</Text>
                    <StayDropDown
                        data={BEDROOMS_OPTIONS}
                        placeholder="Bedrooms"
                        onSelectedItem={selectedItem => dispatch(filterActions.setBedrooms(selectedItem))}
                        defaultOption={filterState.bedrooms}
                    />
                </View>
                <View style={styles.dropdown}>
                    <Text style={styles.label}>Bathrooms</Text>
                    <StayDropDown
                        data={BATHROOMS_OPTIONS}
                        placeholder="Bathrooms"
                        onSelectedItem={selectedItem => dispatch(filterActions.setBathrooms(selectedItem))}
                        defaultOption={filterState.bathrooms}
                    />
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: '100%',
    },
    dropdownWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    dropdown: {
        flex: 1,
        marginHorizontal: 5,
    },
    label: {
        paddingLeft: 10,
        fontSize: 16,
        marginBottom: 5,
        color: colors.black,
        fontFamily: 'Poppins',
    },
});

export default BedroomsBathroomsDropdown;
