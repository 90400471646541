/**
 * image-resize-width.const.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

const PROFILE = 200;
const LISTING_THUMBNAIL = 400;
const LISTING_DETAIL = 800;
const LISTING_GALLERY = 1200;

export {
    PROFILE,
    LISTING_THUMBNAIL,
    LISTING_DETAIL,
    LISTING_GALLERY
}