/**
 * interaction-types.enum.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */
const LIKE = 'like';
const UNLIKE = 'unlike';
const VIEW = 'view';
const INTERESTED = 'interested';
const CALL = 'call';
const SHARE = 'share'

const INTERACTION_TYPES = [LIKE, UNLIKE, VIEW, INTERESTED, CALL, SHARE]

export {
    INTERACTION_TYPES,
    LIKE,
    UNLIKE,
    VIEW,
    INTERESTED,
    CALL,
    SHARE
}