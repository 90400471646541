/**
 * AddListingButton.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import React from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useSelector, useDispatch } from 'react-redux';
import { filterActions } from '../../redux/filterSlice';
import colors from '../../strings/colors';

const FilterIcon = () => {

    const { filterVisible } = useSelector(state => state.filter);

    const dispatch = useDispatch();

    const toggleFilter = () => {
        dispatch(filterActions.setFilterVisible(!filterVisible));
    }

    return (
        <TouchableOpacity style={[styles.button, {
            backgroundColor: colors.stay_blue

        }]} onPress={() => toggleFilter()}>
            <Ionicons name={filterVisible ? 'search' : 'filter-outline'} size={22} color="white" />

        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    button: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#007bff', // Customize your button color
        height: 36,
        width: 36,
        marginTop: 0,
        borderRadius: 50, // Adjust for circular shape
        elevation: 3, // Shadow for Android
        shadowOffset: { width: 1, height: 1 }, // Shadow for iOS
        shadowColor: '#333',
        shadowOpacity: 0.3,
        shadowRadius: 2,
        marginLeft: 10,

    },
});

export default FilterIcon;
