import React from 'react';
import { View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import colors from '../../strings/colors';
import { getProfilePicUri } from '../../utils/app_utils';
import ProfilePic from '../common/ProfilePic';

const Agent = ({ agent }) => {
  const onWhatsAppPress = () => {
    // Handle WhatsApp icon press
  };

  const onEmailPress = () => {
    // Handle email icon press
  };

  return (
    <View style={styles.mainContainer}>
      <Text style={styles.agentTitle}>Agent</Text>

      <View style={styles.container}>
        <ProfilePic url={getProfilePicUri(agent._id)} width={80} username={agent.username} />

        <View style={styles.detailsContainer}>
          <Text style={styles.name}>{agent.username}</Text>

          <TouchableOpacity onPress={onWhatsAppPress} style={styles.contactRow}>
            <View  style={styles.contactIcon}>
              <MaterialCommunityIcons name="whatsapp" size={24} color={colors.gray} />
            </View>
            <Text style={styles.iconText}>{agent?.cellphone || 'No Phone Number Provided'}</Text>
          </TouchableOpacity>

          <TouchableOpacity style={styles.contactRow}>
            <View onPress={onEmailPress} style={styles.contactIcon}>
              <MaterialIcons name="email" size={24} color={colors.gray} />
            </View>
            <Text style={styles.iconText}>{agent.email}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    padding: 10,
  },
  agentTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    marginLeft: 30,
    textAlign: 'left',
    fontFamily: 'Poppins-Bold'
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.white,
    padding: 5,
    borderRadius: 30,
  },
  profilePic: {
    width: 80,
    height: 80,
    borderRadius: 40,
  },
  detailsContainer: {
    marginLeft: 10,
  },
  name: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 5,
    fontFamily: 'Poppins-Bold'
  },
  contactRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  contactIcon: {
    marginRight: 10,
  },
    iconText: {
        fontFamily: 'Poppins-Bold',
        color:colors.light_gray
    },
});

export default Agent;
