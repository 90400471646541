/**
 * property-types.enum.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

const APARTMENT = 'Apartment';
const HOUSE = 'House';
const BACHELOR = 'Bachelor';
const TOWNHOUSE = 'Townhouse';
const COMMERCIAL = 'Commercial';
const ERF   = 'Land';
const FARM  = 'Farm';
const INDUSTRIAL = 'Industrial';
const GETTO = 'Getto';

const PROPERTY_TYPES = [ HOUSE,  GETTO,BACHELOR ,TOWNHOUSE, APARTMENT, ERF, FARM, INDUSTRIAL];

export {
    APARTMENT,
    HOUSE,
    BACHELOR,
    TOWNHOUSE,
    COMMERCIAL,
    ERF,
    FARM,
    INDUSTRIAL,
    GETTO,
    PROPERTY_TYPES
}
