/**
 * Loader.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */
import React from 'react';
import { View, ActivityIndicator, StyleSheet, Text } from 'react-native';
import colors from '../../strings/colors';

function Loader({message = 'Loading...'}) {
  return (
    <View style={styles.container}>
      <ActivityIndicator size="large" color={colors.light_gray} />
      <Text style={{fontFamily:"Poppins"}}>{message}</Text>

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eee',  // Optional: If you want a semi-transparent background for the loader
  },
});

export default Loader;
