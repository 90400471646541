import React, { useState } from 'react';
import { View, Text, Image, StyleSheet, TouchableOpacity, Modal, Button } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import colors from '../../strings/colors';
import { EXPO_PUBLIC_API_ENDPOINT } from '../../config/app.conf';
import { FOR_RENT } from '../../config/enums/property-statuses.enum';
import { toCurrency } from '../../utils/app_utils';
import { THUMBNAIL } from '../../config/constants/image-prefixes.const';

const MyListing = ({ listing, onEdit, onView, onDelete }) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleDeletePress = () => {
    setShowDeleteConfirm(true);
  };

  const confirmDelete = (confirm) => {
    if (confirm) {
      onDelete(listing);
    }
    setShowDeleteConfirm(false);
  };

  return (
    <View style={styles.container}>
      <Image source={{ uri: listing.image }} style={styles.image} />
      <View style={styles.info}>
        <Text style={styles.title}>{listing.name}</Text>
        <Text style={styles.price}>
          {`${toCurrency(listing.propertyStatus === FOR_RENT ? listing.propertyRent : listing.purchaseAmount)} - (${listing.propertyStatus})`}
        </Text>
      </View>
      <TouchableOpacity style={styles.editButton} onPress={() => onView(listing)}>
        <MaterialIcons name="remove-red-eye" size={22} color={colors.stay_blue} />
      </TouchableOpacity>
      <TouchableOpacity style={styles.editButton} onPress={() => onEdit(listing)}>
        <MaterialIcons name="edit" size={22} color={colors.stay_blue} />
      </TouchableOpacity>
      <TouchableOpacity style={styles.deleteButton} onPress={handleDeletePress}>
        <MaterialIcons name="delete" size={22} color={colors.red} />
      </TouchableOpacity>

      {/* Confirmation Modal */}
      <Modal
        transparent={true}
        visible={showDeleteConfirm}
        animationType="slide"
        onRequestClose={() => setShowDeleteConfirm(false)}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text>Are you sure you want to delete this listing?</Text>
            <View style={styles.modalButtons}>
              <Button title="Yes" onPress={() => confirmDelete(true)} />
              <Button title="No" onPress={() => confirmDelete(false)} />
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    backgroundColor: colors.white,
    borderRadius: 10,
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: 25,
    marginRight: 10,
  },
  info: {
    flex: 1,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  price: {
    color: 'grey',
    fontFamily: 'Poppins',
  },
  editButton: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 36,
    width: 36,
    borderRadius: 50,
    marginRight: 10,
    backgroundColor: '#eee',
  },
  deleteButton: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 36,
    width: 36,
    borderRadius: 50,
    backgroundColor: '#eee',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  modalButtons: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 15,
    width: '100%',
  },
});

export default MyListing;
