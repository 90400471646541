import React from 'react';
import { TouchableOpacity, StyleSheet, Text, ActivityIndicator } from 'react-native';
import { EvilIcons } from '@expo/vector-icons';
import colors from '../../strings/colors';

const IconButton = ({ title, iconName, onPress, loading=false }) => (
  <TouchableOpacity 
    style={styles.container} 
    onPress={onPress}
    disabled={loading} // Disable the button when it is loading
  >
    {loading ? (
      <ActivityIndicator size="small" color="#fff" />
    ) : (
      <>
        <Text style={styles.text}>{title}</Text>
        <EvilIcons name={iconName} size={28} color="#fff" />
      </>
    )}
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.stay_blue,
    borderRadius: 25,
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    marginBottom: 15,
    height: 50,
  },
  text: {
    fontFamily: 'Poppins',
    color: colors.white,
    fontWeight: 'bold',
    marginRight: 10,
    fontSize: 17,
  },
});

export default IconButton;
