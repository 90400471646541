import React from 'react';
import { View, StyleSheet, Text } from 'react-native';

import RoundedButton from '../../components/common/RoundedButton';
import colors from '../../strings/colors';



const Settings = () => {


  return (
    <View style={styles.container}>
      <View style={styles.layout}>
        <Text style={styles.label}>We are still working on this screen</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  layout: {
    width: '90%',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
  },
  statusText: {
    color: colors.orange,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
  picker: {
    fontFamily: 'Poppins-Bold',
    borderRadius: 25,
    backgroundColor: 'lightgray',
    paddingHorizontal: 16,
    flex: 1,
    padding: 12,
    fontSize: 16,
    borderColor: 'transparent',
    borderWidth: 0,
    marginBottom: 15,
  },
  label: {
    fontFamily: 'Poppins-Bold',
    fontSize: 16,
    marginLeft: 10,
    marginBottom: 8,
  },
});

export default Settings;
