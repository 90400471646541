import React from 'react';
import { Modal, TouchableOpacity, Text, StyleSheet, ActivityIndicator } from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';
import colors from '../../strings/colors';

const GalleryModal = ({ visible, images, onRequestClose }) => {
  const imageUrls = images.map(img => ({ url: img }));

  // Custom loading render
  const renderLoading = () => (
    <ActivityIndicator size="large" color={colors.white}  />
  );

  return (
    <Modal
      visible={visible}
      transparent={true}
      onRequestClose={onRequestClose}
      animationType="slide"
    >
      <ImageViewer 
        imageUrls={imageUrls} 
        loadingRender={renderLoading}
      />

      <TouchableOpacity onPress={onRequestClose} style={styles.closeButton}>
        <Text style={styles.closeButtonText}>Close</Text>
      </TouchableOpacity>
    </Modal>
  );
};

const styles = StyleSheet.create({
  closeButton: {
    position: 'absolute',
    top: 40,
    right: 20,
    backgroundColor: 'white',
    padding: 10,
    borderRadius: 20,
    zIndex: 2,
  },
  closeButtonText: {
    color: 'black',
  },
});

export default GalleryModal;
