/**
 * PropertyDetails.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import React, { useEffect, useState } from 'react';
import { ScrollView, View, Text, TouchableOpacity, StyleSheet, Platform, Dimensions, Image, Switch } from 'react-native';

import colors from '../../strings/colors';
import { Picker } from '@react-native-picker/picker';
import RoundedInput from '../../components/common/RoundedInput';
import { SIZE_UNITS } from '../../config/enums/size-units.enum';
import { APARTMENT, BACHELOR, FARM, HOUSE, PROPERTY_TYPES, TOWNHOUSE } from '../../config/enums/property-types.enum';
import { FOR_RENT, FOR_SALE, PROPERTY_STATUSES } from '../../config/enums/property-statuses.enum';

import { useSelector, useDispatch } from 'react-redux';

import { listingActions } from '../../redux/listingSlice';
import StayDropDown from '../../components/common/StayDropDown';
import { TOWNS } from '../../config/enums/towns.enum';

const screenWidth = Dimensions.get('window').width;

const PropertyDetails = () => {

    const HAS_GARDEN_AND_PETS = [HOUSE,APARTMENT,TOWNHOUSE,BACHELOR]

    const listing = useSelector(state => state.listing);

    const dispatch = useDispatch();


    const handleStatusChange = (selectedStatus) => {
        dispatch(listingActions.setPropertyStatus(selectedStatus));
    };

    const handleTypeChange = (selectedType) => {
        dispatch(listingActions.setPropertyType(selectedType));
    };

    const handleSizeUnitChange = (selectedValue) => {
        dispatch(listingActions.setSizeUnit(selectedValue));
    }



    return (
        <ScrollView style={styles.container}>
            <View style={styles.layout}>
                <Text style={styles.inputLabel}>Short Description</Text>
                <RoundedInput
                    placeholder="E.g. 3 Bedroom House for sale"
                    value={listing.name}
                    onChangeText={(text) => dispatch(listingActions.setName(text))}
                />

                <Text style={styles.inputLabel}>Long Description</Text>

                <RoundedInput
                    placeholder="E.g. A beautiful 3 bedroom house with a garden and a garage."
                    value={listing.description}
                    onChangeText={(text) => dispatch(listingActions.setDescription(text))}
                />

                <Text style={styles.inputLabel}>Property Status</Text>

                <StayDropDown
                    data={PROPERTY_STATUSES.map(status => ({ value: status, key: status }))}
                    placeholder="Select Status"
                    onSelectedItem={handleStatusChange}
                    defaultOption={listing.propertyStatus}
                />
                <Text style={styles.inputLabel}>Property Type</Text>

                <StayDropDown
                    data={PROPERTY_TYPES.map(type => ({ value: type, key: type }))}
                    placeholder="Select Property Type"
                    onSelectedItem={handleTypeChange}
                    defaultOption={listing.propertyType}
                />



                {listing.propertyStatus.toUpperCase() === FOR_RENT.toUpperCase() ? (
                    <>
                        <Text style={styles.inputLabel}>Property Rent</Text>
                        <RoundedInput
                            placeholder="Property Rent"
                            value={listing.propertyRent ? listing.propertyRent.toString() : ''}
                            keyboardType="numeric"
                            onChangeText={(text) => dispatch(listingActions.setPropertyRent(text))}
                        />
                        <Text style={styles.inputLabel}>Property Deposit</Text>
                        <RoundedInput
                            placeholder="Property Deposit"
                            value={listing.propertyDeposit ? listing.propertyDeposit.toString() : ''}
                            keyboardType="numeric"
                            onChangeText={(text) => dispatch(listingActions.setPropertyDeposit(text))}
                        />
                    </>
                ) : (
                    <>
                        <Text style={styles.inputLabel}>Purchase Amount</Text>
                        <RoundedInput
                            placeholder="Purchase Amount"
                            value={listing.purchaseAmount ? listing.purchaseAmount.toString() : ''}
                            keyboardType="numeric"
                            onChangeText={(text) => dispatch(listingActions.setPurchaseAmount(text))}
                        />
                    </>
                )}

                <Text style={styles.inputLabel}>Town</Text>

                <StayDropDown
                    data={TOWNS.map(town => ({ value: town, key: town }))}
                    placeholder="Select Town"
                    onSelectedItem={(selectedTown) => dispatch(listingActions.setTown(selectedTown))}
                    defaultOption={listing.town}
                />

                <Text style={styles.inputLabel}>Location</Text>

                <RoundedInput
                    placeholder="Suburb"
                    value={listing.suburb}
                    onChangeText={(text) => dispatch(listingActions.setSuburb(text))}
                />

                <Text style={styles.inputLabel}>Size</Text>

                <RoundedInput
                    placeholder="Size"
                    value={listing.size.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) => dispatch(listingActions.setSize(text))}
                />

                <Text style={styles.inputLabel}>Size Unit</Text>

                <StayDropDown
                    data={SIZE_UNITS.map(unit => ({ value: `${unit.name} (${unit.value})`, key: unit.value }))}
                    placeholder="Select Size Unit"
                    onSelectedItem={handleSizeUnitChange}
                    defaultOption={listing.sizeUnit}
                />

                {listing.propertyType!==FARM && <><Text style={styles.inputLabel}>Number of Rooms</Text>

                <RoundedInput
                    placeholder="Number of Rooms"
                    value={listing.numberOfRooms.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) => dispatch(listingActions.setNumberOfRooms(text))}
                /></>}

                {HAS_GARDEN_AND_PETS.includes(listing.propertyType) && <><Text style={styles.inputLabel}>Number of Bathrooms</Text>

                <RoundedInput
                    placeholder="Number of Bathrooms"
                    value={listing.numberOfBathrooms.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) => dispatch(listingActions.setNumberOfBathrooms(text))}
                />

                <Text style={styles.inputLabel}>Number of Parking</Text>

                <RoundedInput
                    placeholder="Number of Parking"
                    value={listing.numberOfParking.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) => dispatch(listingActions.setNumberOfParking(text))}
                />

                <Text style={styles.inputLabel}>Number of Garages</Text>

                <RoundedInput
                    placeholder="Number of Garages"
                    value={listing.numberOfGarages.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) => dispatch(listingActions.setNumberOfGarages(text))}
                />

                <Text style={styles.inputLabel}>Garden</Text>

                <Switch
                    trackColor={{ false: '#767577', true: colors.stay_blue }}
                    thumbColor={colors.white}
                    ios_backgroundColor="#3e3e3e"
                    value={listing.garden}
                    onValueChange={(value) => dispatch(listingActions.setGarden(value))}
                />

                <Text style={styles.inputLabel}>Pets Allowed</Text>

                <Switch
                    trackColor={{ false: '#767577', true: colors.stay_blue }}
                    thumbColor={colors.white}
                    ios_backgroundColor="#3e3e3e"
                    value={listing.petsAllowed}
                    onValueChange={(value) => dispatch(listingActions.setPetsAllowed(value))}
                /></>}

            </View>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 5,
        backgroundColor: colors.width,
    },
    layout: {
        flexDirection: 'column',
    },
    picker: {
        borderWidth: 1,
        borderColor: colors.white,
        borderRadius: 25,
        marginBottom: 10,
        fontFamily: 'Poppins',
    },

    inputLabel: {
        fontFamily: 'Poppins',
        fontSize: screenWidth * 0.04,
        marginBottom: 5,
        marginLeft: 20,
        color: colors.light_gray2
    },
});

export default PropertyDetails;

