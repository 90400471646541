/**
 * NewListing.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import React, { useEffect, useState } from 'react';
import { ScrollView, View, StyleSheet, Dimensions } from 'react-native';
import colors from '../../strings/colors';
import { ProgressSteps, ProgressStep } from 'react-native-progress-steps';
import Listing from '../../components/landing/Listing';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import PropertyDetails from './PropertyDetails';
import ImagesComponent from './ImagesComponent';
import Toast from 'react-native-toast-message';
import { useCreateListingMutation, useUpdateListingMutation, useUploadListingImageMutation } from '../../redux/api';
import Loader from '../../components/common/Loader';
import { uploadListingImage } from '../../utils/app_utils';
import { MY_LISTINGS } from '../../config/constants/nav-routes.const';

const screenWidth = Dimensions.get('window').width;

const NewListing = () => {

    const userState = useSelector(state => state.user);
    const companyState = useSelector(state => state.company);
    const listingState = useSelector(state => state.listing);

    const [isPropertyDetailsErrors, setIsPropertyDetailsErrors] = useState(false);
    const [isImagesErrors, setIsImagesErrors] = useState(false);

    const [createListing, createListingResult] = useCreateListingMutation();

    const [updateListing, updateListingResult] = useUpdateListingMutation();

    const [uploadingImages, setUploadingImages] = useState(false);

    const [loadingMessage, setLoadingMessage] = useState('Uploading Images...');

    const navigation = useNavigation();

    const { images } = useSelector(state => state.listing);

    const progressStepsStyle = {
        activeStepIconBorderColor: colors.stay_blue, // blue color for the active step icon border
        activeLabelColor: colors.stay_blue,         // blue color for the active label
        activeStepNumColor: 'white',          // white color for the active step number
        activeStepIconColor: colors.stay_blue,       // blue color for the active step icon
        labelFontFamily: 'Poppins',
        marginBottom: 40,
        borderWidth: 4
    };

    const onNextStep = () => {
        setIsPropertyDetailsErrors(false);
        if (listingState.name == '') {
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'Listing short description is required',
            });
            setIsPropertyDetailsErrors(true);
            return;
        }

        if (listingState.town == '') {
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'Town is required',
            });
            setIsPropertyDetailsErrors(true);
            return;
        }

        if (listingState.suburb == '') {
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'Suburb is required',
            });
            setIsPropertyDetailsErrors(true);
            return;
        }

        if (listingState.propertyRent == 0 && listingState.purchaseAmount == 0) {
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'Rent or purchase amount is required',
            });
            setIsPropertyDetailsErrors(true);
            return;
        }

        if (listingState.numberOfRooms == 0) {
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'Number of rooms is required',
            });
            setIsPropertyDetailsErrors(true);
            return;
        }
    }

    const onImageSelectedStep = () => {
        setIsImagesErrors(false);
        if (images.length == 0) {
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'At least one image is required',
            });
            setIsImagesErrors(true);
            return;
        }
    }



    const onSubmission = async () => {


        if(listingState._id!==''){ 
            setLoadingMessage('Updating Listing...');
            updateListing({
                ...listingState,
                created_by: userState._id,
            });

            return;
        }

        setLoadingMessage('Creating Listing...');

        const filteredListingState = Object.fromEntries(
            Object.entries(listingState).filter(([_, value]) => value !== '')
        );
    
        try {
            const createdListing = await createListing({
                ...filteredListingState,
                created_by: userState._id,
            }).unwrap();
    
            setUploadingImages(true);
    
            for (let i = 0; i < images.length; i++) {
                await uploadListingImage(images[i], createdListing._id, i)
            }
    
            Toast.show({
                type: 'success',
                text1: 'Success',
                text2: 'Listing and images uploaded successfully',
            });

            navigation.navigate(MY_LISTINGS);
    
        } catch (error) {
            console.log(error);
            
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: error.message || 'An error occurred',
            });
        } finally {

            setUploadingImages(false);
        }
    };

    useEffect(() => {
        if(updateListingResult.isSuccess){
            Toast.show({
                type: 'success',
                text1: 'Success',
                text2: 'Listing updated successfully',
            });
            navigation.navigate(MY_LISTINGS);
        }
        if(updateListingResult.isError){
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: updateListingResult.error.data.message || 'An error occurred',
            });
        }
    }, [updateListingResult.isLoading]);
    

    useEffect(() => {
        if (!userState._id) {
            // Redirect to login
            navigation.navigate('Login');
        }
    }, [userState]);

    if (createListingResult.isLoading || updateListingResult.isLoading || uploadingImages) {
        return (
            <Loader message={loadingMessage} />
        );
    }

    return (
        <View style={styles.container}>
            <ProgressSteps {...progressStepsStyle} >
                <ProgressStep label="Details" errors={isPropertyDetailsErrors} onNext={onNextStep} >
                    <PropertyDetails />
                </ProgressStep>
                <ProgressStep label="Images" errors={isImagesErrors} onNext={onImageSelectedStep}>
                    <ScrollView>
                        <ImagesComponent />
                    </ScrollView>
                </ProgressStep>
                <ProgressStep label="Finish" onSubmit={onSubmission} >
                    <ScrollView styles={{
                        flex: 1,
                        padding: 5,
                        backgroundColor: '#eee',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Listing data={{
                            image: images[0],
                            ...listingState
                        }} />
                    </ScrollView>
                </ProgressStep>
            </ProgressSteps>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 5,
        backgroundColor: colors.white,
    },
    layout: {
        flexDirection: 'column',
    },


    picker: {
        borderWidth: 1,
        borderColor: colors.white,
        borderRadius: 25,
        marginBottom: 10,
        fontFamily: 'Poppins',

    },

    inputLabel: {
        fontFamily: 'Poppins',
        fontSize: screenWidth * 0.04,
        marginBottom: 5,
        marginLeft: 20,
        color: colors.light_gray2
    },

    imageContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    imageWrapper: {
        position: 'relative',
        margin: 5,
    },
    image: {
        width: 150,
        height: 150,
        borderRadius: 10,
    },
    removeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: 'red',
        padding: 5,
    },
    removeButtonText: {
        color: 'white',
        fontFamily: 'Poppins',
    },
});

export default NewListing;

