/**
 * listingsSlice.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import { createSlice } from '@reduxjs/toolkit';

export const listingsSlice = createSlice({
    name: 'listings',
    initialState: {
        listings: [],
        loading: false,
        error: null,
    },
    reducers: {
        setListings: (state, action) => {
            state.listings = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const listingsActions = listingsSlice.actions;