/**
 * property-statuses.enum.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

const FOR_SALE = 'For Sale';
const FOR_RENT = 'For Rent';
const SOLD = 'Sold';
const RENTED = 'Rented';

const PROPERTY_STATUSES = [FOR_SALE, FOR_RENT, SOLD, RENTED];

export {
    FOR_SALE,
    FOR_RENT,
    SOLD,
    RENTED,
    PROPERTY_STATUSES
}