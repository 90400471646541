import React, { useState, useRef, useEffect } from 'react';
import { ScrollView, View, Image, TouchableOpacity, Text, Alert, StyleSheet } from 'react-native';
import { Camera } from 'expo-camera';
import * as ImagePicker from 'expo-image-picker';
import { useSelector, useDispatch } from 'react-redux';
import { listingActions } from '../../redux/listingSlice';
import RoundedButton from '../../components/common/RoundedButton';
import { useDeleteListingImageMutation, useGetListingImagesQuery } from '../../redux/api';
import { getFormData, uploadImage, uploadListingImage } from '../../utils/app_utils';
import * as ImageManipulator from 'expo-image-manipulator';
import { DETAIL, GALLERY, THUMBNAIL } from '../../config/constants/image-prefixes.const';
import { LISTING_DETAIL, LISTING_GALLERY, LISTING_THUMBNAIL } from '../../config/constants/image-resize-width.const';
import Toast from 'react-native-toast-message';
import { EXPO_PUBLIC_API_ENDPOINT } from '../../config/app.conf';
import Loader from '../../components/common/Loader';

const ImagesComponent = () => {
    const cameraRef = useRef(null);
    const [isCameraVisible, setIsCameraVisible] = useState(false);

    const  { images, _id } = useSelector(state => state.listing);

    const { data: apiImages, error: apiError, isLoading: apiLoading, isSuccess } = useGetListingImagesQuery(_id);

    const [isLoading, setIsLoading] = useState(false);

    const [loadingMessage, setLoadingMessage] = useState('Loading Listing Images...');

    const [deleteImage, deleteImageResult] = useDeleteListingImageMutation();

    const dispatch = useDispatch();


   

    const takePhoto = async () => {
        if (!cameraRef.current) return;
        try {
            const photo = await cameraRef.current.takePictureAsync({
                quality: 1,
                allowsEditing: true,
                aspect: [4, 3],
            });

            if (!photo.cancelled) {
                dispatch(listingActions.setImage(photo.uri));

                if (_id !== "") {
                    setLoadingMessage('Uploading Image...');
                    setIsLoading(true);
                    await uploadListingImage(photo.uri, _id, images.length+1);
                    setIsLoading(false);
                    Toast.show({
                        type: 'success',
                        text1: 'Success',
                        text2: 'Image uploaded successfully',
                    });
                }
            }
        } catch (error) {
            console.error(error);
            Alert.alert('Error', 'An error occurred while taking the photo.');
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'An error occurred while taking the photo.',
            });
        }

        setIsCameraVisible(false); // Hide camera after taking the photo
    };

    const requestCameraPermission = async () => {
        const cameraPermission = await Camera.requestCameraPermissionsAsync();
        if (cameraPermission.status === 'granted') {
            setIsCameraVisible(true);
        } else {
            Alert.alert('No access to camera', 'Sorry, we need camera permissions to make this work!');
        }
    };

    const pickImage = async () => {
        const galleryPermission = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (galleryPermission.status !== 'granted') {
            Alert.alert('No access to gallery', 'Sorry, we need gallery permissions to make this work!');
            return;
        }

        try {
            const result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: true,
                aspect: [1, 1],
                quality: 1,
            });

            if (!result.canceled) {

                dispatch(listingActions.setImage(result.assets[0].uri));

                if (_id !== "") {
                    setIsLoading(true);
                    setLoadingMessage('Uploading Image...');
                    await uploadListingImage(result.assets[0].uri, _id, images.length+1);
                    Toast.show({
                        type: 'success',
                        text1: 'Success',
                        text2: 'Image uploaded successfully',
                    });
                    setIsLoading(false);
                } 
            }
        } catch (error) {
            console.error(error);
            Alert.alert('Error', 'An error occurred while selecting the image.');
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'An error occurred while selecting the image.',
            });
        }
    };

    const removeImage = async (uri) => {

        //check if the image is from the API

        if (uri.includes(EXPO_PUBLIC_API_ENDPOINT) && _id !== '') {
            setLoadingMessage('Deleting Image...');
            //delete the image from the API
            deleteImage(uri.split('/').pop());
             
            //delete gallery image
            deleteImage(uri.split('/').pop().replace(THUMBNAIL, GALLERY));

        }

        dispatch(listingActions.removeImage(uri));
    };

    useEffect(() => {
        if (deleteImageResult.isSuccess) {
            console.log('Image deleted successfully');
        }
        if (deleteImageResult.isError) {
            console.log(deleteImageResult.error);
        }
    }, [deleteImageResult.isLoading]);

    useEffect(() => {
        if (isSuccess && _id !== '') {
            dispatch(listingActions.clearImages());

            const thumbnail_images = apiImages.filter(image => image.filename.includes(THUMBNAIL));

            for (let i = 0; i < thumbnail_images.length; i++) {
                dispatch(listingActions.setImage(`${EXPO_PUBLIC_API_ENDPOINT}/stream/${thumbnail_images[i].filename}`));
            }
        }

        if (apiError) {
            console.log(apiError);
        }
    }, [apiLoading]);


    if(apiLoading && _id!=='' || deleteImageResult.isLoading || isLoading){
        return <Loader message={loadingMessage} />
    }

    return (
        <ScrollView style={styles.container}>
            <View style={styles.layout}>
                {isCameraVisible ? (
                    <Camera style={styles.camera} ref={cameraRef}>
                        <View style={styles.cameraButtons}>
                            <TouchableOpacity onPress={takePhoto} style={styles.captureButton}>
                                <Text style={styles.buttonText}>Capture</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => setIsCameraVisible(false)} style={styles.cancelButton}>
                                <Text style={styles.buttonText}>Cancel</Text>
                            </TouchableOpacity>
                        </View>
                    </Camera>
                ) : (
                    <>
                        <RoundedButton title="Pick an image from gallery" onPress={pickImage} />
                        <RoundedButton title="Take a photo" onPress={requestCameraPermission} />

                        <View style={styles.imageContainer}>
                            {images.map((uri, index) => (
                                <View key={uri} style={styles.imageWrapper}>
                                    <Image source={{ uri }} style={styles.image} onError={(e) => console.log('Image loading error:', e.nativeEvent.error)} />
                                    <TouchableOpacity onPress={() => removeImage(uri)} style={styles.removeButton}>
                                        <Text style={styles.removeButtonText}>Remove</Text>
                                    </TouchableOpacity>
                                </View>
                            ))}
                        </View>
                    </>
                )}
            </View>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 5,
        backgroundColor: '#eee',
    },
    layout: {
        flex: 1,
        alignItems: 'center',
    },
    camera: {
        width: '100%',
        height: 500,
        marginBottom: 20,
    },
    cameraButtons: {
        position: 'absolute',
        bottom: 10,
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
    },
    captureButton: {
        backgroundColor: 'blue',
        padding: 10,
        borderRadius: 10,
    },
    cancelButton: {
        backgroundColor: 'grey',
        padding: 10,
        borderRadius: 10,
    },
    buttonText: {
        color: 'white',
        fontFamily: 'Poppins',
    },
    imageContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    imageWrapper: {
        position: 'relative',
        margin: 5,
    },
    image: {
        width: 150,
        height: 150,
        borderRadius: 10,
    },
    removeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: 'red',
        padding: 5,
    },
    removeButtonText: {
        color: 'white',
        fontFamily: 'Poppins',
    },
});

export default ImagesComponent;
