/**
 * Navigator.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */
import React, { useState, useCallback, useMemo, memo } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Image, View } from 'react-native';
import colors from '../strings/colors';
import Login from '../screens/Auth/Login';
import Register from '../screens/Auth/Register';
import Profile from '../screens/Main/Profile';
import ChangePassword from '../screens/Auth/ChangePassword';
import Settings from '../screens/Main/Settings';
import MenuIcon from '../components/stay/MenuIcon';
import MenuPopup from '../components/stay/MenuPopup';
import Landing from '../screens/Main/Landing';
import FilterIcon from '../components/common/FilterIcon';
import AddListingButton from '../components/common/AddListingButton';
import ProfileIcon from '../components/common/ProfileIcon';
import ViewListing from '../screens/ViewListing/ViewListing';
import NewListing from '../screens/NewListing/NewListing';
import { EXPO_PUBLIC_API_ENDPOINT } from '../config/app.conf';
import { useSelector } from 'react-redux';
import MyListings from '../screens/MyListings/MyListings';
import { ACCOUNT, CHANGE_PASSWORD, HOME, LOGIN, MY_LISTINGS, NEW_LISTING, PROFILE, REGISTER, RESET_PASSWORD, VIEW_LISTING } from '../config/constants/nav-routes.const';

import ResetPassword from '../screens/Auth/ResetPassword';

const logo = require('../../assets/images/stay_logo.png');

const Stack = createStackNavigator();



const logoIcon = logo;

console.log('ENDPOINT', EXPO_PUBLIC_API_ENDPOINT);

const LogoHeader = memo(() => (

    <Image
        style={{
            width: 130,
            height: 40,
            marginBottom: 0,
        }}
        source={logoIcon}
        resizeMode="contain"
    />
));

const RightHeader = memo(() => {
    const user_state = useSelector(state => state.user);
    return (
    <View style={{ flexDirection: 'row' }}>
        {user_state._id!=='' && <AddListingButton key={Math.random()} />}
        <ProfileIcon key={Math.random()} />

    </View>
    )
});

const LeftHeader = memo(() => {
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [icon, setIcon] = useState('menu-outline');

    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [filterIcon, setFilterIcon] = useState('filter-outline');

    const user_state = useSelector(state => state.user);

    const toggleFilter = useCallback(() => {
        if (isFilterVisible) {
            setIsFilterVisible(false);
            setFilterIcon('filter-outline');
        } else {
            setIsFilterVisible(true);
            setFilterIcon('close');
        }
    }, [isFilterVisible]);

    const toggleMenu = useCallback(() => {
        if (isMenuVisible) {
            setIsMenuVisible(false);
            setIcon('menu-outline');
        } else {
            setIsMenuVisible(true);
            setIcon('close');
        }
    }, [isMenuVisible]);

    return (
        <View style={{ flexDirection: 'row' }}>
            {user_state._id!=='' && <MenuIcon key={Math.random()} onPress={toggleMenu} icon={icon} />}
            <FilterIcon key={Math.random()} onPress={toggleFilter} icon={filterIcon} />
            <MenuPopup key={Math.random()} visible={isMenuVisible} close={toggleMenu} />
        </View>
    );
});

function Navigator() {

    const listing = useSelector(state => state.listing);


    const commonHeaderOptions = useMemo(() => ({
        headerTitle: () => <LogoHeader />,
        headerRight: () => <RightHeader />,
        headerLeft: () => <LeftHeader />,
        headerTitleAlign: 'center',
        headerStyle: {
            backgroundColor: colors.white,
            shadowColor: '#fff',
        },
    }), []);



    return (
        <Stack.Navigator initialRouteName={HOME}>
            <Stack.Screen name={LOGIN} options={{  headerTitle: "" }} component={Login} />
            <Stack.Screen name={REGISTER} component={Register} options={{ headerTitle: "New Account" }} />
            <Stack.Screen name={RESET_PASSWORD} options={{ headerShown: false }} component={ResetPassword} />

           
            <Stack.Screen
                name={HOME}
                component={Landing}
                options={commonHeaderOptions}
            />
            <Stack.Screen
                name={VIEW_LISTING}
                component={ViewListing}
                options={{
                    headerTransparent: true,
                    headerTitle: '',
                    headerTintColor: '#fff', // If you have header buttons, set an appropriate color
                }}
            />

            <Stack.Screen
                name={NEW_LISTING}
                component={NewListing}
                options={{
                    headerTitle: listing._id!==''? 'Edit Listing' : 'New Listing',
                }}
            />
            <Stack.Screen name={MY_LISTINGS} component={MyListings} options={{ headerTitle: "My Listings" }}/>
            <Stack.Screen name={PROFILE} component={Profile} options={{ headerTitle: "Profile" }} />
            <Stack.Screen name={ACCOUNT} component={Settings} options={{ headerTitle: "Account" }} />
            <Stack.Screen name={CHANGE_PASSWORD} component={ChangePassword} options={{ headerTitle: "" }} />
            
        </Stack.Navigator>
    );
}

export default Navigator;
