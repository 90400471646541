import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import RoundedButton from '../../components/common/RoundedButton';
import colors from '../../strings/colors';
import StayIcon from '../../components/common/StayIcon';
import RoundedInput from '../../components/common/RoundedInput';
import Terms from '../../components/common/Terms';
import { usePhoneSearchMutation } from '../../redux/api'; 
import { useNavigation } from '@react-navigation/core';
import Loader from '../../components/common/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../redux/userSlice';

import Toast from 'react-native-toast-message';



const ResetPassword = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [searchResult, setSearchResult] = useState('');

  const [phoneSearch, phoneSearchResult] = usePhoneSearchMutation();
 

  const dispatch = useDispatch();

  const navigation = useNavigation();

  const user_state = useSelector(state => state.user);

  //term accepted
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleSearch = () => {
    if (termsAccepted) {
      phoneSearch({ cellphone: phoneNumber });
    } else {
      setSearchResult('Please accept the terms and conditions');
    }
  };

  useEffect(() => {
    if(user_state){
        if(user_state._id !== ''){
          if(user_state.loggedIn){
            navigation.navigate('Jackpot');
          }else{
            navigation.navigate('Login');
          }
        
       }
     }
 }, []);

  useEffect(() => {
    if (phoneSearchResult.data) {
      switch (phoneSearchResult.data.status) {
        case 'active':
          dispatch(userActions.setUser(phoneSearchResult.data));
          navigation.navigate('Login')
          break;
        case 'incomplete_profile':
          dispatch(userActions.setUser(phoneSearchResult.data));
          navigation.navigate('Register')
          break;
        default:
          Toast.show({
            type: 'error',
            position: 'top',
            text1: 'Error',
            text2: phoneSearchResult.data.message
          });
          setSearchResult(phoneSearchResult.data.message);
          break;
      }
    }

    
  }, [phoneSearchResult.isLoading]);

  if(phoneSearchResult.isLoading){
    return (
      <Loader/>
    );
  }

  return (
   
      <View style={styles.content}>
      <StayIcon title={'Password Reset'} description={'Request New Password'} />
        <RoundedInput placeholder="Your Email" keyboardType='email' onChangeText={setPhoneNumber} />
        <RoundedButton title="CONTINUE" onPress={handleSearch} />
        <Text style={styles.searchResult}>{searchResult}</Text>
      </View>
   
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    fontFamily: 'Poppins',
    backgroundColor: colors.white,
    justifyContent: 'center',
    
  },
  content: {
    flex: 1,
    padding: 20,
    justifyContent: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
  searchResult: {
    marginTop: 20,
    fontFamily: 'Poppins',
    textAlign: 'center',
    color: colors.orange,
    fontSize: 16,
  },
});

export default ResetPassword;
