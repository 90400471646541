import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import colors from '../../strings/colors';
import { filterActions } from '../../redux/filterSlice';
import StayDropDown from '../common/StayDropDown';

const PRICE_OPTIONS = [
    { key: '0', value: '$0' },
    { key: '50000', value: '$50,000' },
    { key: '100000', value: '$100,000' },
    { key: '150000', value: '$150,000' },
    { key: '200000', value: '$200,000' },
    { key: '250000', value: '$250,000' },
    { key: '300000', value: '$300,000' },
    { key: '350000', value: '$350,000' },
    { key: '400000', value: '$400,000' },
    { key: '450000', value: '$450,000' },
    { key: '500000', value: '$500,000' },
    // Add more price options as needed
];

const PriceRangeDropdown = () => {
    const filterState = useSelector(state => state.filter);
    const dispatch = useDispatch();

    return (
        <View style={styles.container}>
            <View style={styles.dropdownWrapper}>
                <View style={styles.dropdown}>
                    <Text style={styles.lable}> Min Price </Text>
                    <StayDropDown
                        data={PRICE_OPTIONS}
                        placeholder="Price Low"
                        onSelectedItem={selectedItem => dispatch(filterActions.setPriceLow(selectedItem))}
                        defaultOption={filterState.priceLow}
                    />
                </View>
                <View style={styles.dropdown}>
                    <Text style={styles.lable}> Max Price </Text>
                    <StayDropDown
                        data={PRICE_OPTIONS}
                        placeholder="Price High"
                        onSelectedItem={selectedItem => dispatch(filterActions.setPriceHigh(selectedItem))}
                        defaultOption={filterState.priceHigh}
                    />
                </View>

            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: '100%',
    },
    dropdownWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    dropdown: {
       
        flex: 1,
        marginHorizontal: 5,
    },

    lable: {
        paddingLeft: 10,
        fontSize: 16,
        marginBottom: 5,
        color: colors.black,
        fontFamily: 'Poppins',
    },
});

export default PriceRangeDropdown;
