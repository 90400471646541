import React from 'react'
import { Image, StyleSheet, Text } from 'react-native'
import colors from '../../strings/colors'
const stayIcon = require('../../../assets/images/stay_logo.png');

function StayIcon({ title, description }) {
  return (
    <>
      <Image
        style={[styles.icon, {
          width: 300,
          height: 80,
          marginBottom: 10,

        }]}
        source={stayIcon}  // Adjust the path to your image
      />
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.des}>{description}</Text>

    </>

  )
}

const styles = StyleSheet.create({
  icon: {
    width: 80,
    height: 80,
    alignSelf: 'center',
    marginBottom: 10,
  },
  title: {
    fontFamily: 'Poppins-Bold',
    fontSize: 24,
    color: colors.gray,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  des: {
    fontFamily: 'Poppins-Bold',
    fontSize: 16,
    color: colors.light_gray,
    textAlign: 'center',
    marginBottom: 20,
  },

});

export default StayIcon