/**
 * size-units.enum.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

const SQUARE_METER = {
    name: 'Square Meter',
    value: 'm²'
};
const SQUARE_FEET = {
    name: 'Square Feet',
    value: 'ft²'
};
const HECTARE ={
    name: 'Hectare',
    value: 'ha'
};
const ACRE = {
    name: 'Acre',
    value: 'ac'
};

const SIZE_UNITS = [SQUARE_METER, SQUARE_FEET, HECTARE, ACRE];

export {
    SQUARE_METER,
    SQUARE_FEET,
    HECTARE,
    ACRE,
    SIZE_UNITS
}