/**
 * image-prefixes.const.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

const PROFILE = 'profile';
const PHOTO = 'photo';
const THUMBNAIL = 'thumbnail';
const DETAIL = 'detail';
const GALLERY = 'gallery';

export {
    PROFILE,
    PHOTO,
    THUMBNAIL,
    DETAIL,
    GALLERY
}