/**
 * companySlice.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import { createSlice } from '@reduxjs/toolkit';

export const companySlice = createSlice({
    name: 'company',
    initialState: {
        _id: '',
        name:'',
        registration_number: '',
        vat_number:'',
        phone_number: '',
        endorsement_company: '',
        tax_number: '',
        NEAB_number: '',
        contact_person: '',
        contact_person_phone_number: '',
        contact_person_email: '',
        contact_person_ID_number: '',

        email: '',
        address: '',
        country: '',
        website: '',
        postal_address: '',
        employee_number_counter: 0,
        employee_number_prefix: '',

        //customer number
        customer_number_counter: 0,
        customer_number_prefix: '',

        //supplier number
        supplier_number_counter: 0,
        supplier_number_prefix: '',

        //job card number
        job_card_number_counter: 0,
        job_card_number_prefix: '',

        //invoice number
        invoice_number_counter: 0,
        invoice_number_prefix: '',

        //quote number
        quote_number_counter: 0,
        quote_number_prefix: '',

        //sale number

        sale_number_counter: 0,
        sale_number_prefix: '',

        //purchase order number
        purchase_order_number_counter: 0,
        purchase_order_number_prefix: '',

        //requistion order number
        requisition_order_number_counter: 0,
        requisition_order_number_prefix: '',


        //delivery note number
        delivery_note_number_counter: 0,
        delivery_note_number_prefix: '',

        //sale order number
        sale_order_number_counter: 0,
        sale_order_number_prefix: '',

        //recieving number
        recieving_number_counter: 0,
        recieving_number_prefix: '',

        //sale agreement number
        sale_agreement_number_counter: 0,
        sale_agreement_number_prefix: '',



        //default recievable account
        default_receivable_account: '',

        //default payable account
        default_payable_account: '',

        //default cash account
        default_cash_account: '',

        //default bank account
        default_bank_account: '',

        //default tax account

        default_tax_account: '',

        //default stock account

        default_inventory_account: '',

        //default cost of goods sold account

        default_cost_of_goods_sold_account: '',

        //default sales account

        default_sales_account: '',

        tax_included: false,

        fisical_year_start: '01 January',

        created_by: '',
        license: '',
        currency: '',

        //banking details

        bank_name: '',
        bank_branch: '',
        bank_account_number: '',
        bank_account_holder: '',
        bank_account_type: '',
        branch_code:'',

        //default invoice comment

        default_invoice_comment: '',




        //bulk sms

        bulk_sms_username: '',
        bulk_sms_password: '',

        job_card_done_sms_template: '',
        new_invoice_sms_template: '',
        created_at: '',
        deleted: false,
    },
    reducers: {
        setCompany: (state, action) => {
            state._id = action.payload._id
            state.name = action.payload.name
            state.registration_number = action.payload.registration_number
            state.vat_number = action.payload.vat_number
            state.phone_number = action.payload.phone_number
            state.endorsement_company = action.payload.endorsement_company
            state.tax_number = action.payload.tax_number
            state.NEAB_number = action.payload.NEAB_number
            state.contact_person = action.payload.contact_person
            state.contact_person_phone_number = action.payload.contact_person_phone_number
            state.contact_person_email = action.payload.contact_person_email
            state.contact_person_ID_number = action.payload.contact_person_ID_number
            state.email = action.payload.email
            state.address = action.payload.address
            state.country = action.payload.country
            state.website = action.payload.website
            state.postal_address = action.payload.postal_address
            state.employee_number_counter = action.payload.employee_number_counter
            state.employee_number_prefix = action.payload.employee_number_prefix
            state.customer_number_counter = action.payload.customer_number_counter
            state.customer_number_prefix = action.payload.customer_number_prefix
            state.supplier_number_counter = action.payload.supplier_number_counter
            state.supplier_number_prefix = action.payload.supplier_number_prefix
            state.job_card_number_counter = action.payload.job_card_number_counter
            state.job_card_number_prefix = action.payload.job_card_number_prefix
            state.invoice_number_counter = action.payload.invoice_number_counter
            state.invoice_number_prefix = action.payload.invoice_number_prefix
            state.quote_number_counter = action.payload.quote_number_counter
            state.quote_number_prefix = action.payload.quote_number_prefix
            state.sale_number_counter = action.payload.sale_number_counter
            state.sale_number_prefix = action.payload.sale_number_prefix
            state.purchase_order_number_counter = action.payload.purchase_order_number_counter
            state.purchase_order_number_prefix = action.payload.purchase_order_number_prefix
            state.requisition_order_number_counter = action.payload.requisition_order_number_counter
            state.requisition_order_number_prefix = action.payload.requisition_order_number_prefix
            state.delivery_note_number_counter = action.payload.delivery_note_number_counter
            state.delivery_note_number_prefix = action.payload.delivery_note_number_prefix
            state.sale_order_number_counter = action.payload.sale_order_number_counter
            state.sale_order_number_prefix = action.payload.sale_order_number_prefix
            state.recieving_number_counter = action.payload.recieving_number_counter
            state.recieving_number_prefix = action.payload.recieving
        },
        clearCompany: (state) => {
            state._id = ''
            state.name = ''
            state.registration_number = ''
            state.vat_number = ''
            state.phone_number = ''
            state.endorsement_company = ''
            state.tax_number = ''
            state.NEAB_number = ''
            state.contact_person = ''
            state.contact_person_phone_number = ''
            state.contact_person_email = ''
            state.contact_person_ID_number = ''
            state.email = ''
            state.address = ''
            state.country = ''
            state.website = ''
            state.postal_address = ''
            state.employee_number_counter = 0
            state.employee_number_prefix = ''
            state.customer_number_counter = 0
            state.customer_number_prefix = ''
            state.supplier_number_counter = 0
            state.supplier_number_prefix = ''
            state.job_card_number_counter = 0
            state.job_card_number_prefix = ''
            state.invoice_number_counter = 0
            state.invoice_number_prefix = ''
            state.quote_number_counter = 0
            state.quote_number_prefix = ''
            state.sale_number_counter = 0
            state.sale_number_prefix = ''
            state.purchase_order_number_counter = 0
            state.purchase_order_number_prefix = ''
            state.requisition_order_number_counter = 0
            state.requisition_order_number_prefix = ''
            state.delivery_note_number_counter = 0
            state.delivery_note_number_prefix = ''
            state.sale_order_number_counter = 0
            state.sale_order_number_prefix = ''
            state.recieving_number_counter = 0
            state.recieving_number_prefix = ''
        }
    },
});

export const companyActions = companySlice.actions;
